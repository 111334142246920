<template>
	<!-- <div v-if="dotsLoading" class="mt-2">
		<div class="flex items-center justify-center w-full h-16 skeleton mt-4 tableCornerRadius bg-white">
			<dots-loader/>
		</div>
	</div> -->
	<div class="bg-white" :class="this.$route.path.includes('org/frameworks') ? 'tableCornerRadiusForFramework' : 'tableCornerRadius'">
		<div class="table-container">
			<table>
				<thead>
					<th scope="col" v-for="presentColumn in columnNames" :key="presentColumn" :class="getColumnHeadWidth(presentColumn)" class="bg-white capitalize" @click="sort(presentColumn)">
					   <div class="flex" :class="getPaddingForColumn(presentColumn)">
							<div class="cursor-pointer">{{ getColumnLabel(presentColumn) }}</div>
							<div class="sort cursor-pointer" :class="className == presentColumn ? classSort : 'opacity-50'" v-if="getColumnLabel(presentColumn) != ''"></div>
						</div>
					</th>
					<!-- <th scope="col" v-for="column in columnNames" :key="column" :class="getColumnHeadWidth(column)" class="bg-white cursor-pointer capitalize" @click="sort(column)">
						<div class="flex pl-5" v-if="column == 'priority'">
							<div>{{ column }}</div>
						<div class="sort cursor-pointer" :class="className == column && !columnObject ? classSort : 'opacity-50'"></div>
						</div>
						<div class="flex items-center" v-else-if="column == 'complianceId'">
                            <div>{{ "Id" }}</div>
                            <div class="sort cursor-pointer" :class="className == column && !columnObject ? classSort : 'opacity-50'"></div>
                        </div>
                        <div class="flex justify-center items-center" v-else-if="column == 'compliancePeriod'">
                            <div>{{ "Period" }}</div>
                            <div class="sort cursor-pointer" :class="className == column && !columnObject ? classSort : 'opacity-50'"></div>
                        </div>
                        <div class="flex justify-center items-center" v-else-if="column == 'complianceStatus'">
                            <div>{{ "Status" }}</div>
                            <div class="sort cursor-pointer" :class="className == column && !columnObject ? classSort : 'opacity-50'"></div>
                        </div>  
						<div class="flex justify-center items-center" v-else-if="column == 'deleteIcon'">
                        </div> 
						<div
							class="flex justify-center items-center"
							v-else-if="column == 'Date of Target' || column == 'Total Number of Controls' || column == 'KPI\'s' || column == 'Created on' || column == 'frameWorks' || column == 'Target (%)' || column == 'Weightage (%)' || column == 'no.of Kpis' || column == 'weightage (%)'||column=='statusCode'"
						>
							<div>{{ column }}</div>
							<div class="sort cursor-pointer" :class="className == column && !columnObject ? classSort : 'opacity-50'"></div>
						</div>
						<div class="flex" v-else-if="column == 'Status'">
							<div :class="{ 'pl-8': column == 'Status' }">{{ column }}</div>
							<div class="sort cursor-pointer" :class="className == column && !columnObject ? classSort : 'opacity-50'"></div>
						</div>
						<div class="flex w-24" v-else-if="column == 'Target Time Period' || column == 'targetTimePeriod' || column == 'Reporting Frequency' || column == 'reportingFrequency'">
							<div>{{ column }}</div>
							<div class="sort cursor-pointer pl-4" :class="className == column && !columnObject ? classSort : 'opacity-50'"></div>
						</div>
						<div class="flex pl-5" v-else-if="column == 'startDate'">
							<div>{{ column }}</div>
							<div class="sort cursor-pointer pl-4" :class="className == column && !columnObject ? classSort : 'opacity-50'"></div>
						</div>
						<div class="flex" v-else>
							<div>{{ column }}</div>
							<div class="sort cursor-pointer" :class="className == column && !columnObject ? classSort : 'opacity-50'"></div>
						</div>
					</th> -->
					<tr v-if="visibility">
						<td v-for="(name, index) in columnNames" class="bg-white" :key="index" :class="getFilterStyles(name)">
							<select class="priorityStyles m-auto" :class="filters[name] == null ? 'inputboxstylingForSelect' : 'stylingForInputboxstylingForSelect'" v-if="name == 'priority'" v-model="filters[name]">
								<option :value="filters.name" selected disabled hidden>Select</option>
								<option class="optionColors" v-for="(value, index) in priorityArray" :key="index" :value="value.id">{{ value.label }}</option>
							</select>
							<select class="resizeComplianceStatus" :class="filters[name] == null ? 'inputboxstylingForSelect' : 'stylingForInputboxstylingForSelect'" v-else-if="name == 'complianceStatus'" v-model="filters[name]">
								<option :value="filters.name" selected disabled hidden>Select</option>
								<option class="optionColors" v-for="option in getComplianceStatusOptions()" :key="option.id" :value="option.id">{{ option.value }}</option>
							</select>
							<div v-else-if="name == 'statusCode'" class="flex justify-center">
								<select class="priorityStyles" :class="filters[name] == null ? 'inputboxstylingForSelect' : 'stylingForInputboxstylingForSelect'" v-model="filters[name]">
									<option :value="filters.name" selected disabled hidden>Select</option>
									<option class="optionColors" v-for="option in getFindingStatusOptions()" :key="option.id" :value="option.id">{{ option.value }}</option>
								</select>
						    </div>
							<select class="inputboxstylingForSelect resize" v-else-if="name == 'Status'" v-model="filters[name]">
								<option :value="filters.name" selected disabled hidden>Select</option>
								<option v-for="(value, index) in status" :key="index" :value="value">{{ value }}</option>
							</select>
							<select class="widthForSelect" v-else-if="name == 'criticality'" :class="filters[name] == null ? 'inputboxstylingForSelect' : 'stylingForInputboxstylingForSelect'" v-model="filters[name]">
								<option :value="filters.name" selected disabled hidden>Select</option>
								<option class="optionColors" v-for="value in getCriticalityOptions()" :key="value.id" :value="value.id">{{ value.value }}</option>
							</select>
							<select class="inputboxstylingForSelect resize" v-model="filters[name]" v-else-if="name == 'Target Time Period' || name == 'targetTimePeriod' || name == 'Reporting Frequency' || name == 'reportingFrequency'">
								<option :value="filters.name" selected hidden disabled>Select</option>
								<option v-for="(pair, index) in controlRecurringArray" :key="index" :value="pair.id">{{ pair.label }}</option>
							</select>
							<select v-else-if="name == 'controlPriority'"  class="widthForSelect" :class="filters[name] == null ? 'inputboxstylingForSelect' : 'stylingForInputboxstylingForSelect'" v-model="filters[name]">
								<option :value="filters.name" selected disabled hidden>Select</option>
								<option class="optionColors" v-for="pair in dbPriority" :key="pair" :value="pair.value">{{ pair.key }}</option>
							</select>
							<div v-else-if=" name == 'actionButtonAtCompliance' || name == 'actionButtonAtObjective' || name == 'actionButtonForKPIs' || name == 'compliancePeriod' || name == 'TotalNumberofControls' || name == 'No. of Controls' || name == 'no.of controls' || name == 'weightage (%)' || name == 'frameWorks'|| name == 'no.of Kpis' || name == 'Weightage (%)' || name == 'Date of Target' || name == 'startDate' || name == 'createdAt' ||  name=='endDate' || name=='Date of Target' || name=='KPIs' || name == 'No. of Controls' || name == 'kpiDelete' || name == 'deleteIcon'"></div>
							<input v-else-if = " name == 'Id' " type="text" class="inputboxstylingForControlID" placeholder="Search" v-model="filters[name]" />
							<input type="text" placeholder="Search" v-else class="searchForInputBoxStyling" v-model="filters[name]" />
						</td>
						<td v-if="tabelActionBtn"></td>
					</tr>
				</thead>
				<!-- <tbody v-if="columnObject">
				<tr class="cursor-pointer">
					<td class="relative h-10" :colspan="7">
						<div class="flex justify-center"><p class="text-xs font-normal">No Records Found</p></div>
					</td>
				</tr>
			</tbody> -->
			    <tbody v-if="loadData == true">
                    <tr class="cursor-pointer empty rowHeight">
                        <td class="relative h-5" :colspan="columnNames.size">
                            <div class="flex items-center justify-center w-full h-16 skeleton">
								<dots-loader/>
                            </div>
                        </td>
                    </tr>
                </tbody>
				<tbody v-else-if="sortedData.length == 0 " class="emptyFixed">
					<tr class="cursor-pointer rowHeight">
						<td class="relative h-10" :colspan="columnNames.size">
							<div class="flex justify-center"><p class="text-xs font-normal">No Records Found</p></div>
						</td>
					</tr>
               </tbody>
				<tbody v-else class="tbodylast">
					<tr v-for="(row, index) in sortedData" :key="row" class="cursor-pointer">
						<td v-for="column in columnNames" :key="column" @mouseout="doFalse()" @click="showBlockAction(row, index, column)" class="cursor-pointer relative" :class="column == 'priority' ? 'flex items-center justify-center' : ''">
							<!-- {{column}} -->
							<!-- Mapping -->
							<div v-if="(column == 'Target Time Period' || column == 'targetTimePeriod' || column == 'Reporting Frequency' || column == 'reportingFrequency') && typeof row[column] === 'number'">
								<div>
									{{
										controlRecurringArray.find((l) => {
											return l.id == row[column];
										}).label
									}}
								</div>
							</div>
							<div v-else-if="column == 'Date of Target' || column == 'KPI\'s' || column == 'Created on' || column == 'frameWorks' || column == 'Target (%)' || column == 'Weightage (%)' || column == 'no.of Kpis' || column=='weightage (%)' || column=='TotalNumberofControls'">
								<div class="center-content">{{row[column]}}</div>
							</div>
							<div class="flex" v-else-if="column == 'startDate'">
								<div class="center-content">{{row[column]}}</div>

							</div>
							<div class="" v-else-if="column == 'compliancePeriod'">
								{{row[column]}}
							</div>
							<div v-else-if="column == 'deleteIcon'||column == 'kpiDelete'">
								<div class="flex">
									<img src="@/assets/deleteIcon.svg" class="cursor-pointer" @click="emitDeleteRowEvent(row,index)"/>
								</div>
							</div>
							<div v-else-if="column == 'team'">
								<div class="table-ellipsis">
									<div @mousemove="toolTip(column, $event, index, row, 'text')">
										{{ getTeamName(row[column]) }}
									</div>
								</div>
							</div>
							<div v-else-if="column == 'entity'">
								<p class="cursor-pointer text-xs">{{ getLocationName(row[column]) }}</p>
							</div>
							<div v-else-if="column == 'No. of Controls'" class="center-content">
								<a @click="navigate(row, option, column)" class="anchorUnderline">{{row[column]}}</a>
							</div>
							<!-- <div v-else-if="column == 'Entity'">
								<p class="cursor-pointer text-xs">{{ getEntityName(row[column]) }}</p>
							</div> -->
							<div v-else-if="column == 'statusCode'" class="flex items-center justify-center">
								<div class="flex items-center justify-center rounded h-5 w-28" :class="bgColorForFindingStatus(row[column])">
									<div :class="textColorForFindingStatus(row[column])">
										<!-- {{row[column]}} -->
										<!-- {{ row[column]  }} -->
										{{getStatusLabelFromColumn(row[column],column)}}
									</div>
								</div>
							</div>
							<div v-else-if="column == 'KPIs'" class="pl-3">
								<a @click="navigate(row, option, column)" class="anchorUnderline">{{row[column]}}</a>
							</div>
							<!-- <div v-else-if="column === 'no.of controls'">
								<div class="pl-9 kpis-hover" @click.stop="goToSystemControlMappingPage(row)">
									{{row[column]}}
								</div>
							</div> -->
							<div v-else-if="column == 'no.of controls'" class="pl-9">
								<a @click="navigate(row, option, column)" class="anchorUnderline">{{row[column]}}</a>
							</div>
							<div v-else-if="column == 'complianceStatus'" class="">
								<div class="flex items-center justify-center rounded h-5 w-24" :class="colorComplianceBg(row[column])">
									<div :class="colorComplianceText(row[column])">
										{{getStatusLabelFromColumn(row[column],column)}}
									</div>
								</div>
							</div>
							<div v-else-if="column == 'criticality'" class="flex items-center justify-center">
								<div class="flex items-center justify-center rounded h-5 w-24" :class="colorPriorityBg(row[column])">
									<div :class="colorPriorityText(row[column])">
										{{getStatusLabelFromColumn(row[column],column)}}
									</div>
								</div>
							</div>
							<div v-else-if="column == 'controlPriority'" class="flex justify-center items-center">
								<div
									class="flex items-center justify-center rounded h-5 w-24"
									:class="colorLabelBg(row[column])"
								>
									<div :class="colorLabelText(row[column])">
										{{ getProirityLabel(row[column]) }}
									</div>
								</div>
								
							</div>
							<div v-else-if="column == 'findingCategory'">
								{{getStatusLabelFromColumn(row[column],column)}}
							</div>
							<div class="flex items-center justify-center rounded h-5 w-28" :class="colorPriorityBg(row[column])" v-else-if="column == 'priority' && typeof row[column] === 'number'">
								<div :class="colorPriorityText(row[column])">
									<div v-if="row[column]">
										{{
											priorityArray.find((l) => {
												return l.id === row[column];
											}).label
										}}
									</div>
									<div v-else>-</div>
								</div>
							</div>
							<!-- Tasks -->
							<div class="flex items-center justify-center rounded h-5 w-28" :class="colorTaskStatusBg(row[column])" v-else-if="column == 'Status'">
								<div :class="colorTaskStatusText(row[column])">
									{{ row[column] }}
								</div>
							</div>
							<div v-else-if="column == 'actionButtonAtCompliance'">
                            <!-- <fieldset> -->
                                <div class="flex justify-end items-center w-5 h-5 ImageResponsive" >
                                    <img src="@/assets/ActionIcon.svg" class="h-5 w-5 cursor-pointer" @click="calculateDropdownPosition($event,index,column)"/>
                                </div>
                                <ul class="card shadow-sm zIndexingForActionDropDown absolute cursor-pointer actionButtonStyling" :class="isNearBottom(index) ? 'dropdown-up' : 'zIndexingForActionDropDown1'" :style="isNearBottom(index) ? {} : {left: dynamicLeftPosition + 'px',top: dynamicTopPosition + 'px',display: showDropDown ? 'block' : 'none'}" v-if="showBlock && showBlockindex === index && this.$route.path.includes('/compliance')" >
                                    <li class="cursor-pointer lastChild border-lightgrey hover:bg-lightgrey h-7 flex items-center" :class="option.value == 'Reports' ? '' : 'border-b'" v-for="(option, index) in optionsValue" @click="optionClickedOnAction(option.value,row)" :key="index" >
                                        
										<div class="flex items-center" v-if="option.value == 'Edit'">
											<img src="@/assets/editIcon.svg" class="mx-2.5 cursor-pointer h-5 w-5 " />
											{{ option.value }}
										</div>
										<div class="flex items-center" v-if="option.value == 'View'">
											<img src="@/assets/view.svg" class="mx-2.5 cursor-pointer h-5 w-5 " />
                                            {{ option.value }}
                                        </div>
										<div class="flex items-center" v-if="option.value == 'Start'">
                                            <img src="@/assets/Findings.svg" class="mx-1.5 cursor-pointer h-5 w-5" />
                                            {{ option.value }}
                                        </div>
										<div class="flex items-center" v-if="option.value == 'Controls'">
											<img src="@/assets/controls.svg" class="mx-2.5 cursor-pointer h-5 w-5 " />
                                            {{ option.value }}
                                        </div>
										<div class="flex items-center" v-if="option.value == 'Reports'">
											<img src="@/assets/reports.svg" class="mx-2.5 cursor-pointer h-5 w-5 " />
                                            {{ option.value }}
                                        </div>
                                    </li>
                                </ul>
                            <!-- </fieldset> -->
                        </div>
						<div v-else-if="column == 'actionButtonForKPIs'">
                            <fieldset>
                                <div class="flex justify-end items-center w-5 h-5 ImageResponsive">
                                    <img src="@/assets/ActionIcon.svg" class="h-5 w-5 cursor-pointer" @click="calculateDropdownPosition($event,index,column)"/>
                                </div>
                                <ul class="card shadow-sm zIndexingForActionDropDown absolute cursor-pointer actionButtonStyling" :class="isNearBottom(index) ? 'dropdown-up1' : 'zIndexingForActionDropDown2'" :style="isNearBottom(index) ? {} : {left: dynamicLeftPosition + 'px',top: dynamicTopPosition + 'px',display: showDropDown ? 'block' : 'none'}"  v-if="showBlock && showBlockindex === index" >
                                    <li class="cursor-pointer lastChild border-lightgrey hover:bg-lightgrey h-7 flex items-center" v-for="(option, index) in activeControlOptions" @click="optionClickedOnActionControls(option.value,row)" :key="index" >
										<!-- <div class="flex items-center" v-if="option.displayIcon == 'deleteIcon'">
											<img src="@/assets/deleteIcon.svg" class="mx-2.5 cursor-pointer h-5 w-5 " />
											{{ option.value }}
										</div> -->
										<div class="flex items-center" v-if="option.value == 'KPI'">
											<img src="@/assets/link.svg" class="mx-2.5 cursor-pointer h-5 w-5 " />
                                            {{ option.value }}
                                        </div>
                                    </li>
                                </ul>
                            </fieldset>
                        </div>
						<div v-else-if="column == 'actionButtonAtObjective'">
                            <fieldset>
                                <div class="flex justify-end items-center w-5 h-5 ImageResponsive">
                                    <img src="@/assets/ActionIcon.svg" class="h-5 w-5 cursor-pointer" @click="calculateDropdownPosition($event,index,column)"/>
                                </div>
                                <ul class="card shadow-sm zIndexingForActionDropDown absolute cursor-pointer actionButtonStyling" :class="isNearBottom(index) ? 'dropdown-up1' : 'zIndexingForActionDropDown2'" :style="isNearBottom(index) ? {} : {left: dynamicLeftPosition + 'px',top: dynamicTopPosition + 'px',display: showDropDown ? 'block' : 'none'}" v-if="showBlock && showBlockindex === index" >
                                    <li class="cursor-pointer lastChild border-lightgrey hover:bg-lightgrey h-7 flex items-center" v-for="(option, index) in objectiveOptions" @click="optionClickedOnActionObjective(option.value,row)" :key="index" >
										<!-- <div class="flex items-center" v-if="option.displayIcon == 'deleteIcon'">
											<img src="@/assets/deleteIcon.svg" class="mx-2.5 cursor-pointer h-5 w-5 " />
											{{ option.value }}
										</div> -->
										<div class="flex items-center" v-if="option.value == 'Select'">
											<img src="@/assets/link.svg" class="mx-2.5 cursor-pointer h-5 w-5 " />
                                            {{ option.value }}
                                        </div>
                                    </li>
                                </ul>
                            </fieldset>
                        </div>
							<div v-else>
								<div class="table-ellipsis">
									<p class="fullText" @mousemove="toolTip(column, $event, index, row, 'text')">
										<!-- <div v-if="column == 'KPIs'" class="ml-2">{{ row[column] }}</div> -->
										{{ row[column] }}
									</p>
								</div>
								<!-- </div> -->
								
							</div>
						</td>
						<td class="cursor-pointer " v-if="tabelActionBtn">
							<div class="flex justify-end h-5 ImageResponsive">
								<img src="@/assets/ActionIcon.svg" @click="calculateDropdownPosition($event, index,'outerAction')" class="h-5 w-5 cursor-pointer" />
							</div>
							<!-- <ul class="card ml-2 shadow-sm z-30 absolute cursor-pointer" :class="isNearBottom(index)|| index == 0 ? 'dropdown-up' : ''" v-if="showBlock && showBlockindex === index && this.$route.path.includes('/root-Frameworks')" style="right: 0px; margin-right: 50px; position: absolute">
								<li class="cursor-pointer py-2 hover:bg-lightgrey h-7 w-24 flex items-center" v-for="(option, index) in tableRouteArr" :key="index">
									<div class="flex items-center" v-if="option.displayIcon == 'deleteIcon'" @click="openPopup(row, index, 'actionBtn')">
										<img src="@/assets/deleteIcon.svg" class="h-4 w-4 mx-2.5" />
										{{ option.value }}
									</div>
									<div class="flex items-center" v-else-if="option.displayIcon == 'mapIcon'" @click="navigate(row, option)">
										<img src="@/assets/link.svg" class="h-4 w-4 mx-2.5" />
										{{ option.value }}
									</div>
								</li>
							</ul> -->
							<!-- <ul :class="isNearBottom(index) ? 'dropdown-up' : ''" class="card ml-2 shadow-sm zIndexingForActionDropDown absolute cursor-pointer" v-if="showBlock && showBlockindex == index && this.$route.path.includes('/compliance')">
                                    <li class="cursor-pointer lastChild border-b border-lightgrey hover:bg-lightgrey h-7 flex items-center " v-for="(option, index) in tableRouteArr" @click="optionClickedOnAction(option.value,row)" :key="index" >
                                        <div class="flex items-center" v-if="option.value == 'Edit'">
                                            <img src="@/assets/editIcon.svg" class="mx-1.5 cursor-pointer h-5 w-5 mx-2.5" />
                                            {{ option.value }}
                                        </div>
                                        <div class="flex items-center" v-if="option.value == 'View'">
                                            <img src="@/assets/view.svg" class="mx-1.5 cursor-pointer h-5 w-5 mx-2.5" />
                                            {{ option.value }}
                                        </div>
                                        <div class="flex items-center" v-if="option.value == 'Start'">
                                            <img src="@/assets/Findings.svg" class="mx-1.5 cursor-pointer h-5 w-5 mx-2.5" />
                                            {{ option.value }}
                                        </div>
                                        <div class="flex items-center" v-if="option.value == 'Findings'">
                                            <img src="@/assets/Findings.svg" class="mx-1.5 cursor-pointer h-4 w-4 mx-2.5" />
                                            {{ option.value }}
                                        </div>
                                        <div class="flex items-center" v-if="option.value == 'Controls'">
                                            <img src="@/assets/controls.svg" class="mx-1.5 cursor-pointer h-5 w-5 mx-2.5" />
                                            {{ option.value }}
                                        </div>
                                        <div class="flex items-center" v-if="option.value == 'Reports'">
                                            <img src="@/assets/reports.svg" class="mx-1.5 cursor-pointer h-5 w-5 mx-2.5" />
											{{ option.value }}
										</div>
									</li>
								</ul> -->
							<ul class="card shadow-sm z-30 absolute cursor-pointer" :class="isNearBottom(index)|| index == 1 ? 'dropdown-up1' : 'zIndexingForActionDropDown2'" :style="isNearBottom(index) ? {} : {left: dynamicLeftPosition + 'px',top: dynamicTopPosition + 'px',display: showDropDown ? 'block' : 'none'}"  v-if="showBlock && showBlockindex === index" style="right: 0px; margin-right: 61px; position: absolute">
								<li class="cursor-pointer py-2 hover:bg-lightgrey h-7 w-24 flex items-center" v-for="(option, index) in tableRouteArr" :key="index" @click="navigate(row, option)">
									<div class="flex items-center">
										<img src="@/assets/link.svg" class="h-4 w-4 mx-2.5" />
										{{ option.value }}
									</div>
								</li>
							</ul>
						</td>
					</tr>
				</tbody>
			</table>

			<!-- <div v-if="columnObject || sortedData.length === 0" :colspan="columnNames.size" class="flex items-center justify-center centerNoData">No Records Found</div> -->
		</div>


			<pagination :dynamicPagination="dynamicPagination" @currentPageUpdate="paginationUpdations" :sortedData="sortedData"></pagination>
		
	</div>
</template>
<style scoped>
.inputboxstylingForSelect .optionColors{
   color : #4D4D4D !important;
}
.anchorUnderline:hover{
	color: #119BB9;
	text-decoration: underline;
	text-decoration-thickness: 3px;
    font-weight: bold;
}
.fullText{
    white-space: break-spaces;
    font-size: 12px;
}
.zIndexingForActionDropDown{
    z-index : 65;
    width : 100px;
    margin-left : -20px;
}
.centerNoData {
    font-size: 12px;
    height: 60px;
    padding: 0px 20px;
    font-family: 'Open Sans';
    color: black;
    border-top: 1px solid #e9e9e9;
}
.pendingEvidence{
    background-color: #8600f040;
}
.evidenceUpload{
    background: rgba(21,39,60,0.24);
}
.tbodylast:last-child {
	border-bottom: 1px solid #e9e9e9;
}
.priorityStyles{
  width : 112px;
}
.widthForSelect{
    width : 100px !important;
}
.kpis-hover:hover {
	color: #119BB9;
    text-decoration: underline;
	text-decoration-thickness: 3px;
    font-weight: bold;
}
.table-ellipsis {
  display:-webkit-box !important;
  -webkit-line-clamp: 2 !important;
  -webkit-box-orient: vertical !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
 .actionButtonWidth{
	width : 60px;
 }
 .zIndexingForActionDropDown{
	z-index : 65;
	width : 100px;
	margin-left : -40px;
	position: absolute;
	left: -9px;
}
.zIndexingForActionDropDown1{
	z-index : 65;
	width : 100px;
	margin-left : -40px;
	position: fixed;
}
.zIndexingForActionDropDown2{
	z-index : 65;
	width : 100px;
	margin-left : -40px;
	position: fixed;
}
.findingWidth{
	width : 400px;
}
.widthOfEntity{
	width: 15%;
}
.widthOfComplianceId{
	width: 12%;
}
.widthOfComplianceTitle{
	width: 16%;
}
.widthOfComplianceStartDate{
	width: 8%;
}
.widthElevenpercent{
	width: 11%;
}
.widthFifteenpercent{
	width: 15%;
}
.widthOfComplianceStatus{
	width: 13%;
}
.widthOfCompliancePeriod{
	width: 10%;
}
.widthThreePercent{
	width: 3%;
}
.widthTenPercent{
	width: 10%;
}
.resizeComplianceStatus{
	width:100px ! important;
}
.widthOfComplianceActionButton{
	width: 5%;
}
.widthfivePercent{
	width: 5%;
}
.bgDisabledGrey{
	background-color: rgba(164, 164, 164, 0.2);
}
.bgError{
	background-color: rgba(255, 0, 0, 0.2);
}
.bgdarkred{
	background-color: rgba(192, 0, 0, 0.2);
}
.bgYellow{
	background-color: rgba(255, 153, 0, 0.2);
}
.bgLightGreen{
	background-color: rgba(0, 176, 80, 0.2);
}
.bgGreen{
	background-color: rgba(146, 208, 80, 0.2);
}
.bgElectricGreen{
	background-color: rgba(134, 0, 240, 0.2);
}
.bgForReview{
	background-color: rgba(8, 91, 150, 0.2);
}
.bgPrimary{
	background-color: rgba(21, 42, 67, 0.2);
}
/* Disabled Grey: rgba(164, 164, 164, 0.2)
Error: rgba(255, 0, 0, 0.2)
Dark Red: rgba(192, 0, 0, 0.2)
Yellow: rgba(255, 153, 0, 0.2)
Green: rgba(146, 208, 80, 0.2)
Light Green: rgba(0, 176, 80, 0.2)
Primary: rgba(21, 42, 67, 0.2)
Electric Green: rgba(134, 0, 240, 0.2)
For Review: rgba(8, 91, 150, 0.2) */
</style>
<script lang="ts">
import { emitter, toast } from '@/main';
import _ from 'lodash';
import { defineComponent } from 'vue';
import toolTip from '@/components/toolTip.vue';
import pagination from '@/components/pagination.vue';
import useVuelidate from '@vuelidate/core';
import { required, helpers } from '@vuelidate/validators';
import Multiselect from "vue-multiselect";
import { mapGetters } from 'vuex';
import dotsLoader from '@/components/dotsLoader.vue';
export default defineComponent({
	props: ['tableRowData', 'tabelActionBtn', 'tableRouteArr', 'departmentArr', 'headersData', 'locations','loadData'],
	data(): any {
		return {
			v$: useVuelidate(),
			tableHead: [],
			tableRow: [],
			searchTableRow: [],
			entityId: '',
			visibility: false,
			className: '',
			classSort: '',
			currentSort: 'name',
			currentSortDir: 'asc',
            allEntities:[],
			entitiesArray:[],
			currentPage: '',
			pageSize: '',
			dynamicPagination: '',
			showBlockindex: '',
			showBlock: false,
			isDropdownUp: false,
			showDropdown: false,
			optionRoute: '',
			columns: [
                { key: 'complianceId', value: 'compliance Id' },
                { key: 'title', value: 'Title' },
				{ key: 'controlImportTitle', value: 'Title' },
				{ key: 'activeControlsTitle', value: 'Title' },
				{ key: 'KPItitle', value: 'Title' },
				{ key: 'frameworkTitle', value: 'Title' },
                { key: 'compliancePeriod', value: 'Period' },
                { key: 'startDate', value: 'Start Date' },
                { key: 'endDate', value: 'End Date' },
                { key: 'reviewer', value: 'Reviewer' },
                { key: 'complianceStatus', value: 'Status' },
				{ key: 'findingId', value: 'findingId' },
				{ key: 'taskId', value: 'taskId' },
				{ key: 'taskStatus', value: 'status' },
				{ key: 'findingComment', value: 'finding' },
				{ key: 'complianceName', value: 'Compliance' },
				{ key: 'findingCategory', value: 'Category' },
				{ key: 'teamName', value: 'team' },
				{ key: 'entityName', value: 'entity' },
				{ key: 'controlNumber', value: 'Number' },
                { key: 'controlName', value: 'title' },
                { key: 'statusCode', value: 'Status' },
                { key: 'frameworks', value: 'framework' },
                { key: 'no.of Kpis', value: 'KPIs' },
				{ key: 'createdAt', value: 'CreatedAt' },
				{ key: 'systemName', value: 'System Name' },
				{ key: 'vendorCategory', value: 'Vendor Category' },
				{ key: 'sytemCategory', value: 'Category' },
				{ key: 'EntityofActivity', value: 'Entity' },
				{ key: 'activityTeam', value: 'Team' },
				{ key: 'complianceReviewer', value: 'Team Leader' },
				{ key: 'complianceTitle', value: 'Title' },
				{ key: 'viewComplianceEntity', value: 'Entity' },
				{ key: 'viewComplianceTeam', value: 'Team' },
				{ key: 'viewComplianceFindingTeam', value: 'Team' },
				{ key: 'viewCompliancefinding', value: 'Finding' },
				{ key: 'viewComplianceFindingId', value: 'Id' },
				{ key: 'objectiveEntity', value: 'Entity' },
				{ key: 'objectiveDepartment', value: 'Department' },
				{ key: 'TotalNumberofControls', value: 'Total Number of Controls' },
				{ key: 'KPIs', value: "Link KPI's" },
				{ key: 'clientUniqueId', value: "CCID" },
				{ key: 'controlPriority', value: "Priority" },
				{ key: 'controlOwner', value: "Owner" },
            ],
			pop: false,
			styling: { left: '', bottom: '' },
			tipText: '',
			tipColumn: '',
			tipIndex: '',
			filters: {},
			actionBtn: false,
            dynamicLeftPosition: 0,
            dynamicTopPosition: 0,
			columnObject: false,
			columnValueObj: [],
			allOptionNames: [],
			users: [],
			optionsUserArray: [],
			controlRecurring: ['Yearly', 'Half-Yearly', 'Quarterly', 'Monthly', 'Weekly', 'Daily'],
			priorityArray: [
				{ label: 'Very Low', id: 1 },
				{ label: 'Low', id: 2 },
				{ label: 'Moderate', id: 3 },
				{ label: 'High', id: 4 },
				{ label: 'Critical', id: 5 }
			],
			status: ['Open', 'Closed'],
			controlRecurringArray: [
				{ label: 'Daily', id: 1 },
				{ label: 'Weekly', id: 2 },
				{ label: 'Monthly', id: 3 },
				{ label: 'Quarterly', id: 4 },
				{ label: 'Half-Yearly', id: 5 },
				{ label: 'Yearly', id: 6 }
			],
			dropdownOptions:[
                {
                    key: 'complianceStatus',
                    value: [
                       {
                            id: 10401,
                            value: 'Not started'
                        },
                        {
                            id: 10402,
                            value: 'In progress'
                        },
                        {
                            id: 10403,
                            value: 'Closed'
                        },
						{
							id: 10418,
							value: 'Terminated'
						}
                    ]
               
                },
				{
                    key: 'criticality',
                    value: [
                       	{ value: 'Very Low', id: 1 },
						{ value: 'Low', id: 2 },
						{ value: 'Moderate', id: 3 },
						{ value: 'High', id: 4 },
						{ value: 'Critical', id: 5 }
                    ]
               
                },
                {
                    key: 'taskStatus',
					value: [
                        {
                            id: 10401,
                            value: 'Not Started'
                        },
                        {
                            id: 10414,
                            value: 'Pending Evidence'
                        },
                        {
                            id: 10415,
                            value: 'InReview'
                        },
                        {
                            id: 10416,
                            value: 'Evidence Uploaded'
                        },
                        {
                            id: 10403,
                            value: 'Completed'
                        },
                        
						{
                            id: 10419,
                            value: 'For Review'
                        },
                        {
                            id: 10418,
                            value: 'Terminated'
                        },
 
                    ]
 
               
                },
				{
					key: 'statusCode',
                    value: [
                        {
                            id: 10401,
                            value: 'Open'
                        },
                        {
                            id: 10402,
                            value: 'In Progress'
                        },
                        {
                            id: 10408,
                            value: 'Approval'
                        },
                        {
                            id: 10419,
                            value: 'For Review'
                        },
                        {
                            id: 10415,
                            value: 'For Review'
                        },
                        {
                            id: 10403,
                            value: 'Completed'
                        },
						{
                            id: 10406,
                            value: 'Partial'
                        },
						{
							id: 10418,
							value: "Terminated",
						},
                    ]
 
				},
                {	
                    key:'findingCategory',
                    value: [
                        {
                            id: 1,
                            value: 'Minor'
                        },
                        {
                            id: 2,
                            value: 'Major'
                        },
                        {
                            id: 3,
                            value: 'OFI'
                        },
                        {
                            id: 4,
                            value: 'Observation'
                        }
                    ]
                }
            ],
			optionsValue: [
                {
                    id: 1,
                    value: 'Edit',
                },
                {
                    id: 2,
                    value: 'View',
                },
                {
                    id: 5,
                    value: 'Controls',
                },
				{
					id: 6,
					value: 'Reports',
				}
            ],
			objectiveOptions:[
			    {
                    id: 1,
                    value: 'Select',
                },
			],
			activeControlOptions:[
			{
                    id: 1,
                    value: 'KPI',
                },
			]
		};
	},
	// validations() {
	// 	return {
	// 		sortedData: {
	// 			$each: helpers.forEach({
	// 				reviewer: { required },
	// 				teamOwner: { required }
	// 			})
	// 		}
	// 	};
	// },
	components: {
        pagination,
        Multiselect,
		dotsLoader
    },
	async mounted() {
		this.optionRoute = this.$route.path;
		this.initialize();
		await this.getAllUsers();
		await this.getAllEntities();
		emitter.on('visible', (isBoolean: any) => {
			this.visibility = isBoolean;
			this.visibility == false ? (this.filters = {}) : '';
		});
		emitter.on('*', (type, booleanValue) => {
			if (type == 'apps-sidemenubar' || type == 'toggle-sidebar') {
				this.showBlock == true ? (this.showBlock = false) : '';
			} else {
				if (this.showDropdown === true && type != 'deActiviteToolTip') {
					this.showBlock == true ? (this.showBlock = booleanValue) : '';
				} else if (this.showDropdown === false) {
					this.showBlock == true ? '' : (this.showBlock = booleanValue);
					this.showDropdown = true;
				}
            }
		});
		// let path:any = this.$rout.path.includes('/org/compliance')
		// if(path == true){
			
		// }
		window.addEventListener('resize', this.handleResize);
        // window.addEventListener('scroll', this.updateDropdownPosition);
		this.handleResize();
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.handleResize);
        // window.removeEventListener('scroll', this.updateDropdownPosition);
	},
	computed: {
		sortedData: function (): any {
			return [...this.tableRow]
				.filter((row: any) => {
					for (const key in this.filters) {
						const inputValue = this.filters[key];
						if (inputValue !== '' || row[key]) {
							// if (Number.isInteger(row[key])) {
							if (Number(inputValue) && isNaN(row[key])) {
								// console.log('Number(inputValue)', Number(inputValue));
								if (!String(row[key]).includes(inputValue)) {
									return false;
								}
							} else {
								// if (!isNaN(row[key])) {
								// 	return false;
								// } else {
								if (!row[key].toString().toLowerCase().includes(inputValue.toString().toLowerCase())) {
									return false;
								}
								// }
							}
						}
					}
					return true;
				})
				.sort((a, b) => {
					let modifier = 1;
					if (this.currentSortDir === 'desc') modifier = -1;
					if (a[this.currentSort] < b[this.currentSort]) return -1 * modifier;
					if (a[this.currentSort] > b[this.currentSort]) return 1 * modifier;
					return 0;
				})
				.filter((row, index) => {
					this.dynamicPagination = index + 1;
					let start = (this.currentPage - 1) * this.pageSize;
					let end = this.currentPage * this.pageSize;
					if (index >= start && index < end) return true;
				});
		},
		// columnNames: function (): any {
		// 	let names = new Set();
		// 	for (let row of this.tableRowData) {
		// 		for (let key of Object.keys(row)) {
		// 			if (key != '_id' && key != 'entityId' && key != 'key' && key != 'metrics' && key != 'isformula' && key != 'description' && key != 'kpis') names.add(key);
		// 		}
		// 		this.checkEmptyValues();
		// 	}
		// 	return names;
		// },
		columnNames: function (): any {
            const names = new Set();
            for (const key of this.headersData) {
                if (key != '_id' && key != 'entityId' && key != 'key' && key != 'metrics' && key != 'isformula' && key != 'description' && key != 'kpis') names.add(key);
            }
            // this.checkEmptyValues();
            return names;
        },
		...mapGetters({
			 userInfo: 'userInfo',
			 dbPriority:'dbPriority',
            //  dotsLoading: 'dotsLoading'
		})
	},
	watch: {
		visibility(newValue) {
			if (newValue == false) {
				this.initialize();
			}
		},
		tableRowData: {
			handler(newVal, oldVal) {
				this.tableRow = this.tableRowData;
				this.initialize();
			},
			deep: true
		}
	},
	created() {
		this.getAllUsers();
	},
	methods: {
		getColumnHeadWidth(column:any){
		 let ValueKeys:any = ['findingId','no.of Kpis','frameWork',]
            if(ValueKeys.includes(column)){
               return 'w-32';
            }
			let Values:any = ['no.of controls','Entity','vendor','sytemCategory', 'location','owner','class','family','number','KPI Name','weightage (%)', 'priority']
            if(Values.includes(column)){
               return 'w-40';
            }
			let Keys:any = ['department','statusCode', 'reviewer','compliance Title','entity','team','entityName']
            if(Keys.includes(column)){
               return 'w-36';
            }if(column == 'taskId'){
				return 'w-28';
			}
			if(column == "Id" || column == 'no.of Kpis' || column == 'model' || column == 'Class'|| column == 'createdAt'){
				return 'w-24';
			}if(column == "deleteIcon" || column=='actionButtonForKPIs'){
				return 'actionButtonWidth';
			}if(column == 'frameworkTitle' ){
				return 'w-72'
			}if(column == 'action' || column == 'vendorCategory'|| column == 'systemName'){
				return 'w-48'
			}if(column == 'KPItitle'){
				return 'w-52'
			}if(column == 'departments'||column == 'activeControlsTitle'||column == 'Activities'|| column == 'KPItitle'||column == 'viewComplianceEntity'|| column == 'viewComplianceTeam'|| column == 'Team Owner'|| column == 'Reviewer'|| column == 'viewComplianceFindingTeam'|| column == 'entityName'|| column == 'TotalNumberofControls'||column=='No. of Controls'){
				return 'w-1/5'
			}if(column == 'CCID'|| column == 'weightage (%)'){
				return 'w-1/6'
			}if(column == 'KPI Name'){
				return 'w-1/4'
			}if(column == 'EntityofActivity'||column == 'Activity Owner'||column == 'activityTeam'||column == 'criticality'){
				return 'widthOfEntity'
			}if(column == 'complianceId'){
				return 'widthOfComplianceId'
			}if(column == 'startDate'){
				return 'widthOfComplianceStartDate'
			}if(column == 'complianceTitle'){
				return 'widthOfComplianceTitle'
			}if(column == 'endDate'||column == 'tier'||column == 'KPIs'){
				return 'widthElevenpercent'
			}if(column == 'actionButtonAtCompliance'){
				return 'widthOfComplianceActionButton'
			}if( column=="compliancePeriod"){
				return 'widthOfCompliancePeriod';
			}if(column=="clientUniqueId"||column=='viewComplianceFindingId'||column=='objective'||column=='objectiveDepartment'||column=='objectiveEntity'||column=='responsibility'){
				return 'widthTenPercent'
			}else if(column == 'complianceStatus'){
				return 'widthOfComplianceStatus'
			}else if(column == 'complianceReviewer'||column == 'controlOwner'||column == 'controlPriority'){
				return 'widthFifteenpercent'
			}else if(column == 'viewCompliancefinding'){
				return 'w-1/2'
			}else if(column == 'Date of Target'){
				return 'w-15'
			} else if(column == 'actionButtonAtObjective'){
				return 'widthfivePercent'
			}
			// if(column == 'actionButtonAtCompliance'){
			// 	return 'widthOfComplianceActionButton'
			// }if( column=="compliancePeriod"){
			// 	return 'w-1/5';
			// }
		},
		goToKpisPage(row:any){
			this.$router.push({ name: 'manageKPI', params: { id: row._id }  });
		},
		goToSystemControlMappingPage(row:any){
			this.$router.push({ name: 'systemControlMapping', params: { systemId: row._id }  });
		},
		getPaddingForColumn(column:any){
            // if(column=='complianceStatus'){
            //     return 'flex justify-center items-center'
            // }
			if(column == 'Date of Target'||column == 'controlPriority' || column == 'priority' || column == 'criticality' || column == 'statusCode' || column == 'TotalNumberofControls' || column == 'No. of Controls' || column == 'KPI\'s' || column == 'Created on' || column == 'frameWorks' || column == 'Target (%)' || column == 'Weightage (%)' || column == 'no.of Kpis' || column == 'weightage (%)'){
				return 'flex justify-center items-center'
			}else if(column == 'departments'){
				return 'w-44'
			}else if(column == 'KPI Name' || column == 'KPItitle' || column == 'CCID'){
				return 'w-32'
			}
			else if(column == 'complianceTitle'||column == 'activeControlsTitle'){
				return 'w-36'
			}else if(column == 'compliancePeriod'){
				return 'pl-10 w-36'
			}else if(column == 'complianceStatus'){
				return 'pl-6 w-28'
			}
			else if(column == 'startDate'){
				return 'w-20'
			}
			else if(column == 'complianceId'){
				return 'w-28'
			}
			else if(column == 'endDate'||column == 'clientUniqueId'){
				return 'w-16'
			}
			else if(column == 'Reviewer'){
				return 'w-32'
			}
			else if(column == 'actionButtonAtCompliance'|| column=="actionButtonAtObjective"){
				return 'w-8'
			}
			else if(column == 'objective'||column == 'objectiveDepartment'||column == 'objectiveEntity'||column == 'responsibility'){
				return 'w-24'
			}
			else if(column == 'complianceReviewer'){
				return 'w-24'
			}
        },
		getFilterStyles(columnName: any){
			if (columnName == 'controlPriority'||columnName == 'criticality'){
                return 'flex justify-center items-center';
            }
		},
		getColumnLabel(columnName: any) {
			if (columnName == 'actionButton' || columnName == 'kpiDelete' || columnName == 'deleteIcon' || columnName == 'actionButtonAtCompliance' || columnName == 'actionButtonAtObjective' || columnName=='actionButtonForKPIs'){
                return '';
            }
            const column = this.columns.find((col: { key: any }) => col.key === columnName);
            return column ? column.value : columnName;
        },
		optionForFindingsAction(actionOption: any, rowData: any){
			console.log("rowData", rowData)
			if(actionOption == 'View'){
				if(rowData._id){
					this.$router.push({ name: 'findings', params: { findingId: rowData._id } });
				}
            }
		},
		optionForFindingTasksAction(actionOption: any, rowData: any){
			console.log("rowData", rowData)
			if(actionOption == 'View'){
				if(rowData._id){
					this.$router.push({ name: 'actionTask', params: { findingId: rowData.linkedFindingId, taskId: rowData._id } });
				}
            }
		},
		optionClickedOnActionObjective(actionOption: any, rowData: any){
			if(actionOption == 'Select'){
                // this.$router.push({ name: 'startCompliance', params: { complianceId: rowData._id } });
                this.$router.push({ name: 'objectiveControls', params: { objectiveId: rowData._id } });
            }
		},
		optionClickedOnActionControls(actionOption: any, rowData: any){
			if(actionOption == 'KPI'){
				if (this.$route.path.includes('/root-Frameworks')) {
					this.$router.push({ name: 'manageRootControlMapping', params: { id: rowData._id }, query: { from: 'frameworks' } });
				} else if (this.$route.path.includes('/root/allControls')) {
					this.$router.push({ name: 'manageRootControlMapping', params: { id: rowData._id }, query: { from: 'internalControls' } });
				} else if(this.$route.path.includes('/org/controls/active')){
					this.$router.push({ name: 'manageKPI', params: { id: rowData._id } });
				}
            }
		},
		optionClickedOnAction(actionOption: any, rowData: any){
            let actionObj:any = {
                action: actionOption,
                data:rowData
            }
            if(rowData['complianceStatus'] == 10401 && actionOption=='Edit'){
				if(rowData.createdBy == this.userInfo._id){
					this.$router.push({ name: 'editCompliance', params: { complianceId: rowData._id } });
				}
				else{
                toast.error('Cannot Edit', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
				}
            }
			// else if(actionOption == 'Findings'){
            //     this.$router.push({ name: 'complianceFindings', params: { complianceId: rowData._id } });
            // }
			else if(actionOption == 'Start'){
                // this.$router.push({ name: 'startCompliance', params: { complianceId: rowData._id } });
                this.$router.push({ name: 'start-Compliance', params: { complianceId: rowData._id } });
            }else if(actionOption == 'View'){
				this.$router.push({name:'viewCompliance', params: { complianceId: rowData._id } })
			}else if(actionOption == 'Controls'){
				this.$router.push({name:'complianceControls', params: { complianceId: rowData._id } })
			}else if(rowData['complianceStatus'] == 10418 || rowData['complianceStatus'] == 10402 && actionOption=='Edit'){
				toast.error('Cannot Edit Compliance in this Status', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }
			else{
                emitter.emit('clickedOnDropDownAction',actionObj)
            }
        },
		getComplianceStatusOptions() {
            const complianceStatusOption = this.dropdownOptions.find((option:any) => option.key === 'complianceStatus');
            return complianceStatusOption ? complianceStatusOption.value : [];
        },
		getFindingStatusOptions() {
            const findingStatusOption = this.dropdownOptions.find((option:any) => option.key === 'statusCode');
            return findingStatusOption ? findingStatusOption.value : [];
        },
		getCriticalityOptions(){
			const findingStatusOption = this.dropdownOptions.find((option:any) => option.key === 'criticality');
            return findingStatusOption ? findingStatusOption.value : [];
		},
		getStatusLabelFromColumn(columnName: any, type: any) {
            const option = this.dropdownOptions.find((option: { key: any }) => option.key === type);
            const option1 = option.value.find((soption: { id: any }) => soption.id === columnName);
            return option ? option1.value : [];
        },
		textColorForFindingStatus(status: any) {
            if (status == 10403 ) {
                return ['font-semibold text-lightgreen'];
            } else if (status == 10415) {
                return ['font-semibold text-yellow'];
            } else if (status == 10401) {
                return ['font-semibold text-disabledgrey'];
            } else if (status == 10418) {
                return ['font-semibold text-error'];
            } else if(status == 10402){
                return ['font-semibold mx-2 text-electricGreen']
            } else if(status == 10406 || status == 10408){
                return ['font-semibold mx-2 text-primary']
            } else if(status == 10419){
                return ['font-semibold mx-2 text-forReview']
            } else {
                return '-'
            }
        },
        bgColorForFindingStatus(status: any) {
            if (status == 10403) {
                return ['bgLightGreen border-solid border border-lightgreen'];
            } else if (status == 10415 ) {
                return ['bgYellow border-solid border border-yellow'];
            } else if (status == 10401) {
                return ['bgDisabledGrey border-solid border border-disabledgrey'];
            } else if (status == 10418) {
                return ['bgError border-solid border border-error'];
            } else if (status == 10402) {
                return ['bgElectricGreen border-solid border border-electricGreen'];
            } else if (status == 10406 || status == 10408) {
                return ['bgPrimary border-solid border border-primary'];
            }else if (status == 10419) {
                return ['bgForReview border-solid border border-forReview'];
            } else {
                return '-'
            }
        },
		colorComplianceText(status: any) {
            if (status == 10401) {
                return ['font-semibold text-disabledgrey'];
            } else if (status == 10402 || status == 10415) {
                return ['font-semibold text-yellow'];
            } else if (status == 10403) {
                return ['font-semibold text-lightgreen'];
            } else if(status == 10414){
                return ['font-semibold mx-2 text-electricGreen']
            } else if(status == 10416){
                return ['font-semibold mx-2 text-primary']
            }else if(status == 10417){
                return ['font-semibold mx-2 text-darkred']
            } else if(status == 10418){
                return ['font-semibold mx-2 text-error']
            }
        },
        colorComplianceBg(status: any) {
			if (status == 10401) {
				return ['bgDisabledGrey border-solid border border-disabledgrey'];
			} else if (status == 10402 || status == 10415) {
				return ['bgYellow border-solid border border-yellow'];
			} else if (status == 10403) {
				return ['bgLightGreen border-solid border border-lightgreen'];
			} else if (status == 10414) {
				return ['bgElectricGreen border-solid border border-electricGreen'];
			} else if (status == 10416) {
				return ['bgPrimary border-solid border border-primary'];
			}else if (status == 10417) {
				return ['bgdarkred border-solid border border-darkred'];
			}else if (status == 10418) {
				return ['bgError border-solid border border-error'];
			}
		},
		async getAllUsers() {
			await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/users/getAll`).then((res: any) => {
				this.users = res.data;
			});
			for (var i = 0; i < this.users.length; i++) {
				this.optionsUserArray.push({ label: this.users[i].name, value: this.users[i]._id });
			}
		},
		// isNearBottom(index:any) {
        //     if(((index == 0))&&((this.sortedData.length == 1))){
        //         return false;
        //     }else{
        //         return index >= this.sortedData.length - 2;
		// 		// return true
        //     }
        // },
		handleResize() {
			console.log("jdfghdfg")
			const currentWidth = window.innerWidth;
				console.log("currentWidth",currentWidth)
				console.log("window.screen.width",window.screen.width)
			// Check if the width is less than or equal to 80%
			if (currentWidth >= (window.screen.width * 0.8)) {console.log("jdfghdfg1")
				this.showDropDown = false;
			this.showBlock = false // Close the dropdown
			}
			// Repeat for other widths if needed
			else if (currentWidth >= (window.screen.width * 0.7)) {console.log("jdfghdfg2")
				this.showDropDown = false;
			}
			else if (currentWidth >= (window.screen.width * 0.6)) {console.log("jdfghdfg3")
				this.showDropDown = false;
			}
			else if (currentWidth >= (window.screen.width * 0.5)) {console.log("jdfghdfg4")
				this.showDropDown = false;
			}
		},
		// updateDropdownPosition() {
        //     // If the dropdown is open, adjust its position based on the current scroll
		// 	if (this.showDropDown) {
        //         const actionImage = this.$refs.actionImage; // Reference to action image
        //         if (actionImage) {
        //             const rect = actionImage.getBoundingClientRect();
        //             this.dynamicLeftPosition = rect.left - 40; // Adjust if needed
        //             this.dynamicTopPosition = rect.bottom + 15; // Position below the image
        //         }
        //     }
        // },
		calculateDropdownPosition(event:any, index: any, coloum: any) {
			console.log("sdfuhiu")
			if(coloum == "actionButtonAtCompliance" || coloum == "actionButtonAtObjective" || coloum == "outerAction" || coloum == 'actionButtonForKPIs'){
				// console.log("this.showBlockindex",this.showBlockindex)
				if (this.showBlockindex === index) {
					this.showBlock = !this.showBlock;
					// console.log("this.showBlock",this.showBlock)
					console.log("ïn if blockkkkkk")
				} else {
					// console.log("showBlockindex", this.showBlockindex)
					this.showBlock = true;
					this.showBlockindex = index;
					console.log("ïn elsee bloclk")
				}
			}
			this.showDropdown = false;
			const { clientX, clientY } = event;

			// Calculate the desired position (you might want to adjust this logic)
			this.dynamicLeftPosition = clientX-40;
			const offset = 15; // Adjust this value as needed
            this.dynamicTopPosition = clientY + offset; // Example adjustment
			this.showDropDown = true
			// Optionally, handle boundary checks here
		},
		isNearBottom(index:any) {
			// console.log("((index == 0)||(index == 1))&&((this.sortedData.length == 1)||(this.sortedData.length == 2))",((index == 0)||(index == 1))&&((this.sortedData.length == 1)||(this.sortedData.length == 2))),
			// console.log("index >= this.sortedData.length - 2",index >= this.sortedData.length - 2)
			if(((index == 0)||(index == 1))&&((this.sortedData.length == 1)||(this.sortedData.length == 2))){
				return false;
			}else{
				return index >= this.sortedData.length - 2;
			}
			
		},
		teamOwnerReviewerSame(row: any) {
			// Check if teamOwner and reviewer are defined and if they are the same
			return row.teamOwner && row.reviewer && row.teamOwner === row.reviewer;
		},
		emitDeleteRowEvent(row:any, index: any) {
            // Emit the deleteRowFromTable event with the necessary data
            emitter.emit('deleteKpiRowFromTable', row);
            emitter.emit('deleteRowFromTable', {
                team: row.team,
                entity: row.entity
            });
            emitter.emit('deleteControlFromTable', {
                index: index,
                data: row
            });
        },
        getTeamName(deptId: any) {
            console.log('deptId', deptId);
            console.log('this.departmentArr', this.departmentArr);
            let nameOfTeam = this.departmentArr.find((obj: any) => {
                return obj._id == deptId;
            });
            if (nameOfTeam == undefined) {
                let empty = '';
                return empty;
            } else {
                return nameOfTeam.teamName;
            }
        },
 
		getLocationName(locationId: any) {
			let user = this.locations.find((obj: any) => {
				return obj.value == locationId;
			});
			if (user == undefined) {
				let empty = '';
				return empty;
			} else {
				return user.label;
			}
		},
		getEntityName(entity: any) {
			let entityName = this.entitiesArray.find((obj: any) => {
				return obj.value == entity;
			});
			if (entity === 'global' || entity === 'Global') {
				let empty = 'Global';
				return empty;
			} 
			else if(entityName == undefined){
				let name = 'N/A';
				return name;
			}
			else {
				return entityName.label;
			}
		},
		// checkEmptyValues() {
		// 	for (let row of this.tableRowData) {
		// 		for (let value of Object.values(row)) {
		// 			if (value === '') {
		// 				this.columnObject = true;
		// 				return;
		// 			}
		// 		}
		// 	}
		// 	this.columnObject = false;
		// },
		navigate(row: any, option: any, coloum: any) {
			if (this.$route.path.includes('/org/frameworks')) {
				this.$router.push({ name: 'Manage Control Mapping', params: { id: row._id } });
			} else if (this.$route.path.includes('/controlMapping')) {
				this.$router.push({ name: 'departmentControls', params: { id: row._id } });
			} else if (this.$route.path.includes('/objective')) {
				this.$router.push({ name: 'objectiveControls', params: { objectiveId: row._id } });
			} else if (this.$route.path.includes('/controls/active')) {
				this.$router.push({ name: 'manageKPI', params: { id: row._id } });
			} else if (this.$route.path.includes('/root-Frameworks')) {
				this.$router.push({ name: 'manageRootControlMapping', params: { id: row._id }, query: { from: 'frameworks' } });
			} else if (this.$route.path.includes('/root/allControls')) {
				this.$router.push({ name: 'manageRootControlMapping', params: { id: row._id }, query: { from: 'internalControls' } });
			} else if (this.$route.path.includes('/org/findings')) {
				this.$router.push({ name: 'manageRootControlMapping', params: { id: row._id }, query: { from: 'internalControls' } });
			} else if (this.$route.path.includes('/org/controls/active')) {
				this.$router.push({ name: 'manageKPI', params: { id: row._id }  });
			} else if (this.$route.path.includes('/org/systems')) {
				this.$router.push({ name: 'systemControlMapping', params: { systemId: row._id }  });
			}
		},
		showBlockAction(data: any, index: any, coloum: any) {
			console.log('data show block',data)
			console.log("this.showDropdown1",this.showDropDown)
			// console.log('index show block',index)
			// if(coloum == "actionButtonAtCompliance" || coloum == "actionButtonAtObjective" || coloum == "outerAction" || coloum == 'actionButtonForKPIs'){
			// 	console.log("this.showBlockindex",this.showBlockindex)
			// 	if (this.showBlockindex === index) {
			// 		this.showBlock = !this.showBlock;
			// 		console.log("this.showBlock",this.showBlock)
			// 		console.log("ïn if blockkkkkk")
			// 	} else {
			// 		console.log("showBlockindex", this.showBlockindex)
			// 		this.showBlock = true;
			// 		this.showBlockindex = index;
			// 		console.log("ïn elsee bloclk")
			// 	}
			// } else {
			if(coloum != "actionButtonAtCompliance" && coloum != "actionButtonAtObjective" && coloum != "outerAction" && coloum != 'actionButtonForKPIs'){
				this.openPopup(data, index)
			}
			// }
			
			// this.showDropdown = false;
		},
		initialize() {
			this.tableRow = this.tableRowData;
			this.searchTableRow = this.tableRowData;
		},
		paginationUpdations(currentPage: any, pageSize: any) {
			this.currentPage = currentPage;
			this.pageSize = pageSize;
		},
		sort(tableHead: any) {
			this.className = tableHead;
			if (tableHead === this.currentSort) {
				this.currentSortDir = this.currentSortDir === 'asc' ? 'desc' : 'asc';
			}
			this.currentSort = tableHead;
			this.classSort = this.currentSortDir === 'asc' ? 'sort_asc' : 'sort_desc';
		},
		openPopup(data: any, index: any, actionBtn: any) {
			// this.showBlockAction(data, index);
			let obj = {
				entityId: data.entityId,
				index: index
			};
			let manageKPIpopupdata = {
				entityData: data,
				indexx: index
			};
			let viewpopupdata = {
				entityData: data,
				indexx: index
			};
			let manageControlMappopupdata = {
				entityData: data,
				indexx: index
			};
			let internalControlsPopup = {
				entityData: data,
				indexx: index
			};
			let frameworksPopup = {
				entityData: data,
				indexx: index
			};
			let objectivePopup = {
				entityData: data,
				indexx: index
			};
			let activeControlPopup = {
				entityData: data,
				indexx: index
			};
			let rootControlsPopup = {
				entityData: data,
				indexx: index
			};
			let findingsPopup = {
				entityData: data,
				indexx: index
			};
			let allFindings = {
				entityData: data,
				indexx: index
			};
			let controlMappingData = {
				entityData: data,
				indexx: index
			};
			let allFindingsTasks = {
				entityData: data,
				indexx: index
			};
			let allComplianceView = {
				entityData: data,
				indexx: index
			};

			let rootFrameworksPopup;
			if (actionBtn) {
				rootFrameworksPopup = {
					entityData: data,
					indexx: index,
					action: actionBtn
				};
			} else {
				rootFrameworksPopup = {
					entityData: data,
					indexx: index
				};
			}
			if (this.$route.path.includes('/manageKPI') || this.$route.path.includes('/root/allKpis')) {
				emitter.emit('manageKPIpopupdata', manageKPIpopupdata);
			} else if (this.$route.path.includes('/complianceControls')) {
				// emitter.emit('showKpisViewData', viewpopupdata);
			} else if (this.$route.path.includes('/viewConfiguration')) {
				emitter.emit('internalControls', internalControlsPopup);
			} else if (this.$route.path.includes('/manageControlMap') || this.$route.path.includes('/manageRootControlMapping')) {
				emitter.emit('manageControlMappopupdata', manageControlMappopupdata);
			} else if (this.$route.path.includes('/org/frameworks')) {
				emitter.emit('frameworksPopup', frameworksPopup);
			} else if (this.$route.path.includes('/objective')) {
				emitter.emit('showDataObjective', objectivePopup);
			} else if (this.$route.path.includes('/controls/active')) {
				emitter.emit('activeControlPopup', activeControlPopup);
			} else if (this.$route.path.includes('/root-Frameworks')) {
				emitter.emit('rootFrameworksPopup', rootFrameworksPopup);
			} else if (this.$route.path.includes('/root/allControls')) {
				emitter.emit('rootControlsPopup', rootControlsPopup);
			} else if (this.$route.path.includes('/root/tenant/frameWorks')) {
				emitter.emit('tenenatFrameWorks', data);
			} else if (this.$route.path.includes('/viewCompliance')) {
				emitter.emit('findingsPopup', findingsPopup);
			}else if(this.$route.path.includes('/org/findings')){
				// this.$router.push({ name: 'findings', params: { findingId: rowData._id } });
				emitter.emit('allFindings', allFindings);
			}else if(this.$route.path.includes('/task/findingTasks')){
				// this.$router.push({ name: 'findings', params: { findingId: rowData._id } });
				emitter.emit('allFindingsTasks', allFindingsTasks);
			}  else if(this.$route.path.includes('/org/controls/controlMapping')){
				// this.$router.push({ name: 'findings', params: { findingId: rowData._id } });
				emitter.emit('controlMappingData', controlMappingData);
			} else if(this.$route.path.includes('/org/compliance')){
				emitter.emit('allComplianceView', allComplianceView);
			}
			else {
				emitter.emit('showData', obj);
			}
			if (data.key == 10001) {
				this.$router.push({ name: 'riskName', params: { taskId: data.ID } });
			}
		},
		
		toolTip(column: any, e: any, index: any, row: any, status: any) {
			this.tipText = row[column];
			this.tipColumn = column;
			this.tipIndex = index;

			switch (status) {
				case 'text':
					this.handleTextTooltip(e);
					break;
				case 'circleButton':
					if (e.offsetX < e.target.offsetWidth && e.offsetY < e.target.offsetHeight) {
						this.pop = true;
						this.styling.left = e.offsetX + e.target.offsetLeft + 13 + 'px';
						this.styling.bottom = -15 + 'px';
					}
			}
		},
		handleTextTooltip(e: any) {
			const container = e.currentTarget.closest('.table-ellipsis');
			// console.log("tooltip",container)
			 if (container && this.isTextOverflowed(container)) {
				let obj:any = {
					tipText : this.tipText
				}
				emitter.emit('activiteToolTip',obj)
                
            } 
		},
		isTextOverflowed(element: any) {
			return element.scrollHeight > element.clientHeight;
		},
		colorPriorityText(priority: any) {
			if (priority == 1) {
				return ['text-lightgreen font-semibold'];
			} else if (priority == 2) {
				return ['text-green font-semibold'];
			} else if (priority == 3) {
				return ['text-yellow font-semibold'];
			} else if (priority == 4) {
				return ['text-error font-semibold'];
			} else if (priority == 5) {
				return ['text-darkred font-semibold'];
			}
		},
		colorPriorityBg(priority: any) {
			if (priority == 1) {
				return ['bgLightGreen border-solid border border-lightgreen'];
			} else if (priority == 2) {
				return ['bgGreen border-solid border border-green'];
			} else if (priority == 3) {
				return ['bgYellow border-solid border border-yellow'];
			} else if (priority == 4) {
				return ['bgError border-solid border border-error'];
			} else if (priority == 5) {
				return ['bgdarkred border-solid border border-darkred'];
			}
		},
		colorTaskStatusBg(status: any) {
			if (status == 'Open') {
				return ['bgLightGreen border-solid border border-lightgreen'];
			} else if (status == 'Closed') {
				return ['bgdarkred border-solid border border-darkred'];
			}
		},
		colorTaskStatusText(status: any) {
			if (status == 'Open') {
				return ['text-lightgreen font-semibold'];
			} else if (status == 'Closed') {
				return ['text-darkred font-semibold'];
			}
		},
		doFalse() {
			emitter.emit('deActiviteToolTip')
		},
		async getAllEntities(){
            this.entitiesArray = [];
            await this.$http.get(`${process.env.VUE_APP_ORG_API_URL}/api/org/businessEntities/getall`).then((res: any) => {
                res.data.map((obj:any)=>{
                    let a = {value:obj._id, label:obj.entityName};
                    this.entitiesArray.push(a);
                })
                             
            })
        },
        colorLabelText(value: any) {
            if (value == 1) {
                return ["text-lightgreen font-semibold"];
            } else if (value == 2) {
                return ["text-green font-semibold"];
            } else if (value == 3) {
                return ["text-yellow font-semibold"];
            } else if (value == 4) {
                return ["text-error font-semibold"];
            } else if (value == 5) {
                return ["text-darkred font-semibold"];
            }
        },
        colorLabelBg(value: any) {
            if (value == 1) {
                return ["bgLightGreen border-solid border border-lightgreen"];
            } else if (value == 2) {
                return ["bgGreen border-solid border border-green"];
            } else if (value == 3) {
                return ["bgYellow border-solid border border-yellow"];
            } else if (value == 4) {
                return ["bgError border-solid border border-error"];
            } else if (value == 5) {
                return ["bgdarkred border-solid border border-darkred"];
            }
        },
        getProirityLabel(val:any){
            if (val === 'N/A') { 
                return 'N/A'; 
            } else {
                let a = this.dbPriority.find((obj: any) => obj.value == val);
                return (val && typeof val === 'number') ? a.key : 'N/A';
            }
        },
	}
});
</script>