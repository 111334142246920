import { createStore, mapGetters } from "vuex";
import axios from 'axios'

export default createStore({
    state: {
        toggle:true,
        status: '',
        user: {},
        isLoading:false,
        val:'',

        labelsStatus:'',
        dbLabelsObject:{},

        dbAvailability:[],
        dbBussiness:[],
        dbConfidentiality:[],
        dbFinancial:[],
        dbInformationClassification:[],
        dbIntegrity:[],
        dbLegal:[],
        dbLikelihood:[],
        dbOperations:[],
        dbOrganization:[],
        dbPriority:[],
        dbProcessCriticality:[],
        dbSecurityCategory:[],
        dbSeverity:[],
        dbTechnical:[],
        dbOverAllImpact:[],
    },
    mutations: {
        auth_request(state) {
        
            state.status = 'loading'
        },
        auth_success(state, user) {
            state.status = 'success'
            state.user = user
        },
        auth_error(state) {
            state.status = 'error'
        },
        logout(state) {
            state.status = ''
        },
        
        labels_request(state){
            state.labelsStatus = 'loading'
        },
        labels_success_organisation(state,labelsData){
            state.labelsStatus = 'success'
            state.dbLabelsObject = labelsData
            
            labelsData.map((item:any)=>{
                switch(item.entityId){
                    case 20401:
                        state.dbConfidentiality = item.values;
                        break;
                    case 20402:
                        state.dbIntegrity = item.values;
                        break;
                    case 20403:
                        state.dbAvailability = item.values;
                        break;
                    case 20404:
                        state.dbSecurityCategory = item.values;
                        break;
                    case 20405:
                        state.dbLikelihood = item.values;
                        break;
                    case 20406:
                        state.dbOperations = item.values;
                        break;
                    case 20407:
                        state.dbOrganization = item.values;
                        break;
                    case 20408:
                        state.dbBussiness = item.values;
                        break;
                    case 20409:
                        state.dbTechnical = item.values;
                        break;
                    case 20410:
                        state.dbFinancial = item.values;
                        break;
                    case 20411:
                        state.dbLegal = item.values;
                        break;
                    case 20412:
                        state.dbSeverity = item.values;
                        break;
                    case 20413:
                        state.dbPriority = item.values;
                        break;
                    case 20414:
                        state.dbInformationClassification = item.values;
                        break;
                    case 20415:
                        state.dbProcessCriticality = item.values;
                        break;
                    case 20416:
                        state.dbOverAllImpact = item.values;
                        break;
                }   
            })
        },
        labels_error(state){
            state.labelsStatus = 'error'
        },
        labels_crystalline(state,defaultArray){
            let caseNumbers:any = [
                20401, 20402, 20403, 20404, 20405, 
                20406, 20407, 20408, 20409, 20410, 
                20411, 20412, 20413, 20414, 20415, 20416
            ];

            caseNumbers.map((item:any)=>{
                switch(item){
                    case 20401:
                        state.dbConfidentiality = defaultArray;
                        break;
                    case 20402:
                        state.dbIntegrity = defaultArray;
                        break;
                    case 20403:
                        state.dbAvailability = defaultArray;
                        break;
                    case 20404:
                        state.dbSecurityCategory = defaultArray;
                        break;
                    case 20405:
                        state.dbLikelihood = defaultArray;
                        break;
                    case 20406:
                        state.dbOperations = defaultArray;
                        break;
                    case 20407:
                        state.dbOrganization = defaultArray;
                        break;
                    case 20408:
                        state.dbBussiness = defaultArray;
                        break;
                    case 20409:
                        state.dbTechnical = defaultArray;
                        break;
                    case 20410:
                        state.dbFinancial = defaultArray;
                        break;
                    case 20411:
                        state.dbLegal = defaultArray;
                        break;
                    case 20412:
                        state.dbSeverity = defaultArray;
                        break;
                    case 20413:
                        let a:any =  [
                            {
                                "key": "P1",
                                "value": 5,
                                "description": "consedering as the low"
                            },
                            {
                                "key": "P2",
                                "value": 4,
                                "description": "consedering low"
                            },
                            {
                                "key": "P3",
                                "value": 3,
                                "description": "Show medium level"
                            },
                            {
                                "key": "P4",
                                "value": 2,
                                "description": "consedering High low"
                            },
                            {
                                "key": "P5",
                                "value": 1,
                                "description": "Show medium level"
                            }
                        ];
                        state.dbPriority =  a
                        break;
                    case 20414:
                        let b:any = [
                            {
                                "key": "Public",
                                "value": 1,
                                "description": "consedering as the Public"
                            },
                            {
                                "key": "Internal",
                                "value": 2,
                                "description": "consedering Internal"
                            },
                            {
                                "key": "Confidential",
                                "value": 3,
                                "description": "Confidential"
                            },
                            {
                                "key": "Private",
                                "value": 4,
                                "description": "Private"
                            },
                            {
                                "key": "Top Secret",
                                "value": 5,
                                "description": "Top Secret"
                            }
                        ]
                        state.dbInformationClassification = b;
                        break;
                    case 20415:
                        state.dbProcessCriticality = defaultArray;
                        break;
                    case 20416:
                        state.dbOverAllImpact = defaultArray;
                        break;
                }   
            })
            state.labelsStatus = 'success'
        },
    },
    actions: {
        async getUserInfo({commit,getters}) {
            commit('auth_request')
            return await new Promise((resolve, reject) => {
                axios({ 
                url: `${process.env.VUE_APP_AUTH_API_URL}/userInfo/get`,
                method: 'GET',
                withCredentials: true,
                headers:{
                    Authorization : 'Bearer ' + localStorage.getItem('AUTH_ID')
                } })
                .then( async (resp) => {
                   
                    const user = resp.data[0];
                    user.currentRole = user.roles.find((role: { appId: number; }) => { return role.appId == 10001 }).roleId
                    console.log('user',user);
                    commit('auth_success', user)

                    //after first authentication dispatching labels info
                    if(user.organisationType == 10701){
                        await this.dispatch('getMasterLabelsInfo')
                    }else{
                        await this.dispatch('getOrgLabelsInfo')
                    }
                    
                    //resolving authentication promise
                    resolve(resp)
                })
                .catch(err => {
                    commit('auth_error');
                    reject(err);
                    localStorage.removeItem('AUTH_ID');
                    // window.location.href = window.location.origin+'/onboarding'
                })
            })
        },
        logout({ commit }) {
            return new Promise<void>((resolve) => {
                commit('logout');
                resolve();
            })
        },

        getOrgLabelsInfo({commit}){
            commit('labels_request')

            return  new Promise((resolve, reject) => {
                axios({ 
                url: `${process.env.VUE_APP_ORG_API_URL}/api/org/labels/getall`,
                method: 'GET',
                withCredentials: true,
                headers:{
                    Authorization : 'Bearer ' + localStorage.getItem('AUTH_ID')
                } })
            // })
            .then(resp => {
                    commit('labels_success_organisation', resp.data);
                    resolve(resp)
                })
            .catch(err => {
                    console.log('error in state',err);
                    commit('labels_error')
                    reject(err);
                })
            })
        },

        getMasterLabelsInfo({commit}){
            commit('labels_request')

            return  new Promise((resolve, reject) => {
            axios({ 
                url: `${process.env.VUE_APP_ORG_API_URL}/api/org/master/labels/getall`,
                method: 'GET',
                withCredentials: true,
                headers:{
                    Authorization : 'Bearer ' + localStorage.getItem('AUTH_ID')
                } })
            // })
            .then(resp => {
                    commit('labels_success_organisation', resp.data);
                    resolve(resp)
                })
            .catch(err => {
                    console.log('error in state',err);
                    commit('labels_error')
                    reject(err);
                })
            })
        },
        
        selectedRoute(context, payload) {
         context.state.val=payload
        },
        selectedToggle(context, payload) {
            console.log("Ssss",payload)
            context.state.toggle=payload;
        },
        // selectedRoles(context, payload) {
        //   context.state.roles=payload
        //  }
    
    },
    getters: {
        toggle:state=>state.toggle,
        authStatus: state => state.status,
        userInfo: state => state.user,
        loading:state=>state.isLoading,
        val: state => state.val,
        
        dbLabelsObject:state => state.dbLabelsObject,
        
        dbAvailability:state => state.dbAvailability,
        dbBussiness:state => state.dbBussiness,
        dbConfidentiality:state => state.dbConfidentiality,
        dbFinancial:state => state.dbFinancial,
        dbInformationClassification:state => state.dbInformationClassification,
        dbIntegrity:state => state.dbIntegrity,
        dbLegal:state => state.dbLegal,
        dbLikelihood:state => state.dbLikelihood,
        dbOperations:state => state.dbOperations,
        dbOrganization:state => state.dbOrganization,
        dbPriority:state => state.dbPriority,
        dbProcessCriticality:state => state.dbProcessCriticality,
        dbSecurityCategory:state => state.dbSecurityCategory,
        dbSeverity:state => state.dbSeverity,
        dbTechnical:state => state.dbTechnical,
        dbOverAllImpact:state => state.dbOverAllImpact
    },
})

